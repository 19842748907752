$content_color:#f2f3f8;
$white:#ffffff;
$blue1:#177fc1;
$blue2:#3891dd;
$blue3:#3f5b9c;
$red:#e94840;
$green1:#35b96e;
$green2:#45b487;
$yellow:#e7ae41;
$dark1:#5a6a6d;
$dark2:#2e3c3f;
$dark3:#202b2d;
$header_color:#2e3c3f;
$font_color:#5b627e;

$font1:'Source Sans Pro', sans-serif;
$font2:'Open Sans', sans-serif;

@mixin fill{
    position:absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin anim{
    transition:0.3s all ease;
}

@mixin btn_gradient{
    background: rgba(29,149,224,1);
    background: -moz-linear-gradient(top, rgba(29,149,224,1) 0%, rgba(26,139,209,1) 54%, rgba(23,126,194,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(29,149,224,1)), color-stop(54%, rgba(26,139,209,1)), color-stop(100%, rgba(23,126,194,1)));
    background: -webkit-linear-gradient(top, rgba(29,149,224,1) 0%, rgba(26,139,209,1) 54%, rgba(23,126,194,1) 100%);
    background: -o-linear-gradient(top, rgba(29,149,224,1) 0%, rgba(26,139,209,1) 54%, rgba(23,126,194,1) 100%);
    background: -ms-linear-gradient(top, rgba(29,149,224,1) 0%, rgba(26,139,209,1) 54%, rgba(23,126,194,1) 100%);
    background: linear-gradient(to bottom, rgba(29,149,224,1) 0%, rgba(26,139,209,1) 54%, rgba(23,126,194,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d95e0', endColorstr='#177ec2', GradientType=0 );
}

body{
    font-family: $font2;
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin: 0;
        font-weight: 400;
    }
    ul{
        list-style: none;
        padding: 0;
    }
    a{
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }
}

div.page{
    overflow: hidden;
    @media only screen and (max-width: 991px) {
        overflow: hidden;
    }
}

.more1{
    @include anim;
    @include btn_gradient;
    font-size: 15px;
    line-height: 15px;
    color: $white;
    display: inline-block;
    padding: 18px 28px;
    &.dark{
        background:$dark1;
    }
}

.more2{
    @include anim;
    color: $header_color;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    border: 2px solid $blue2;
    padding: 21px 41px;
    text-transform: uppercase;
    border-radius: 50px;
    line-height: 14px;
    cursor: pointer;
    &:hover{
        background-color: $blue2;
        color: $white;
    }
}

div.default_font{
    font-size: 15px;
    line-height: 26px;
    @media only screen and (max-width: 1500px) {
        font-size: 13px;
        line-height: 24px;
     }
    h1{
        font-size: 44px;
        line-height: 54px;
        @media only screen and (max-width: 1500px) {
            font-size: 39px;
            line-height: 49px;
        }
    }
    h2{
        font-size: 36px;
        line-height: 44px;
        @media only screen and (max-width: 1500px) {
            font-size: 31px;
            line-height: 39px;
        }
    }
    h3{
        font-size: 34px;
        line-height: 42px;
        @media only screen and (max-width: 1500px) {
            font-size: 29px;
            line-height: 37px;
        }
    }
    h4{
        font-size: 31px;
        line-height: 42px;
        @media only screen and (max-width: 1500px) {
            font-size: 24px;
            line-height: 30px;
        }
    }
    h5{
        font-size: 24px;
        line-height: 41px;
        @media only screen and (max-width: 1500px) {
            font-size: 20px;
            line-height: 37px;
        }
    }
    h6{
        font-size: 20px;
        line-height: 28px;
        @media only screen and (max-width: 1500px) {
            font-size: 15px;
            line-height: 23px;
        }
    }
    ul{
        list-style:disc;
        padding-left: 18px;;
    }
    ol{
        padding-left: 18px;
    }
    a{
        color: $blue1;
        text-decoration: underline;
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

header.header{
    position: relative;
    z-index: 3;
    @media only screen and (max-width: 991px) {
        margin-bottom: 15px;
    }
    div{
        &.header_top{
            border-bottom: 1px solid #d9dfe5;
            div.top_holder{
                > div{
                    display: inline-block;
                    vertical-align: middle;
                    padding: 28px 0;
                    font-size: 16px;
                    line-height: 16px;
                    color: #878b91;
                    font-family: $font1;
                    @media only screen and (max-width: 1500px) {
                        font-size: 13px;
                        line-height: 13px;
                    }
                    &.date{
                        width: 21%;
                        border-right: 1px solid #d9dfe5;
                        @media only screen and (max-width: 991px) {
                            display: none;
                        }
                    }
                    &.name_day{
                        width: 38%;
                        border-right: 1px solid #d9dfe5;
                        p{
                            padding:0 29px;
                        }
                        @media only screen and (max-width: 991px) {
                            display: none;
                        }
                    }
                    &.logotypes{
                        padding: 0;
                        text-align: right;
                        width: 41%;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            text-align: center;
                            padding: 10px 0;
                        }
                        ul{
                            li{
                                display: inline-block;
                                vertical-align: middle;
                                margin-right: 9px;
                                img{
                                    @media only screen and (max-width: 1500px) {
                                        height: 37px;
                                        width: auto;
                                    }
                                    @media only screen and (max-width: 991px) {
                                        height: 30px;
                                    }
                                }
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.header_bottom{
            padding-top: 14px;
            div{
                &.container{
                    padding: 0;
                    .row{
                        margin: 0;
                        .col-md-4{
                            padding: 0;
                            &.bottom_left{
                                width: 32%;
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                }
                            }
                            &.bottom_middle{
                                width: 36%;
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                }
                            }
                            &.bottom_right{
                                width: 32%;
                                padding-top: 46px;
                                text-align: right;
                                @media only screen and (max-width: 1500px) {
                                    padding-top: 28px;
                                }
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                    padding-top: 0px;
                                }
                                ul{
                                    li{
                                        display: inline-block;
                                        vertical-align: top;
                                        @media only screen and (max-width: 991px) {
                                            width: 100%;
                                        }
                                        &.acces{
                                            padding-top: 0;
                                            ul{
                                                margin: 0 25px;
                                                padding: 0 27px;
                                                border-left: 1px solid #dadada;
                                                border-right: 1px solid #dadada;
                                                @media only screen and (max-width: 1500px) {
                                                    margin: 0 16px;
                                                    padding: 0 10px;
                                                }
                                                @media only screen and (max-width: 991px) {
                                                    margin: 0;
                                                    padding: 0;
                                                    border: none;
                                                }
                                                li{
                                                    margin-right: 14px;
                                                    @media only screen and (max-width: 991px) {
                                                        width: 33.33%;
                                                        margin-right: 0;
                                                        text-align: center;
                                                        vertical-align: middle;
                                                        margin-top: 10px;
                                                        margin-bottom: 10px;
                                                    }
                                                    cursor: pointer;
                                                    &:last-child{
                                                        margin-right: 0;
                                                    }
                                                }
                                            }
                                        }
                                        &.login_register{
                                            @media only screen and (max-width: 991px) {
                                                margin-bottom: 15px;
                                                text-align: center;
                                            }
                                            a.login_btn{
                                                display: block;
                                                font-size: 15px;
                                                font-weight: 700;
                                                color: $white;
                                                text-align: center;
                                                padding: 17px 0;
                                                @include btn_gradient;
                                                margin-bottom: 15px;
                                                text-decoration: none;
                                                @media only screen and (max-width: 991px) {
                                                    display: inline-block;
                                                    vertical-align: middle;
                                                    width: 49%;
                                                    font-size: 13px;
                                                    padding: 10px 0;
                                                    margin-bottom: 0;
                                                }
                                            }
                                            font-size: 15px;
                                            color: #878b91;
                                            p{
                                                @media only screen and (max-width: 991px) {
                                                    display: inline-block;
                                                    vertical-align: middle;
                                                    width: 49%;
                                                    font-size: 13px;
                                                }
                                                text-transform: uppercase;
                                                a{
                                                    color: #878b91;
                                                    text-decoration: underline;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.logo_box{
                    position: relative;
                    img{
                        max-width: 84%;
                        height: auto;
                        @media only screen and (max-width: 991px) {
                            max-width: 100px;
                        }
                    }
                    div.rwd_btn{
                        display: none;
                        @media only screen and (max-width: 991px) {
                            display: block;
                            width: 30px;
                            position: absolute;
                            top: 15px;
                            right: 0;
                            > div{
                                width: 100%;
                                background-color: $blue2;
                                height: 3px;
                                margin-bottom: 3px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
                &.search_box{
                    padding-top: 38px;
                    @media only screen and (max-width: 1500px) {
                        padding-top: 0;
                    }
                    form{
                        height: 64px;
                        width: 100%;
                        overflow: hidden;
                        border:2px solid #edeef3;
                        border-radius: 20px;
                        position: relative;
                        @media only screen and (max-width: 991px) {
                            height: 34px;
                            border-radius: 10px;
                            font-size: 13px;
                        }
                        label{
                            visibility: hidden;
                            opacity: 0;
                        }
                        input{
                            &.search_input{
                                width: 84%;
                                padding: 0 17px;
                                font-size: 16px;
                                color: #878b91;
                                height: 100%;
                                border: none;
                                position: absolute;
                                top: 0;
                                left: 0;
                                @media only screen and (max-width: 991px) {
                                    font-size: 13px;
                                    padding: 0 10px;
                                }
                            }
                            &.search_submit{
                                border: none;
                                margin: 0;
                                padding: 0;
                                width: 16%;
                                height: 100%;
                                position: absolute;
                                left: 84%;
                                top: 0;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-image: url('./../img/search_icon.png');
                                background-color: #edeef3;
                            }
                        }
                    }
                }
                &.menu_box{
                    background-color: $blue1;
                    margin-top: 16px;
                    nav.menu{
                        @media only screen and (max-width: 991px) {
                            display: none;
                        }
                       > ul{
                            text-align: center;
                          >  li{
                                display: inline-block;
                                vertical-align: top;
                                position: relative;
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                }
                                &:after{
                                    content: '';
                                    border-right: 1px solid rgba(255,255,255,0.2);
                                    position: absolute;
                                    top: 30%;
                                    right: 0;
                                    height: 40%;
                                    @media only screen and (max-width: 991px) {
                                        display: none;
                                    }
                                }
                                &:last-child{
                                    &:after{
                                        display: none;
                                    }
                                }
                                > .on{
                                    a{
                                        background-color: $white;
                                        color: $blue1;
                                    }
                                }
                               > a{
                                    display: block;
                                    font-size: 18px;
                                    font-family: $font1;
                                    color: $white;
                                    padding: 35px;
                                    @include anim;
                                    @media only screen and (max-width: 1500px) {
                                       font-size: 14px;
                                       padding: 28px;
                                    }
                                    @media only screen and (max-width: 991px) {
                                        padding: 15px 0;
                                    }
                                }
                                &:hover{
                                    > ul{
                                        visibility: visible;
                                        opacity: 1;
                                    }
                                   > a{
                                        background-color: $white;
                                        color: $blue1;
                                    }
                                }
                                ul{
                                    position: absolute;
                                    top: 100%;
                                    left: 0;
                                    min-width: 350px;
                                    z-index: 3;
                                    text-align: left;
                                    @include anim;
                                    visibility: hidden;
                                    opacity: 0;
                                    @media only screen and (max-width: 991px) {
                                        width: 100%;
                                        position: relative;
                                        top: auto;
                                        left: auto;
                                        text-align: center;
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                    > li{
                                       > .on{
                                        background-color: #f2f3f8;
                                        font-weight: 700;
                                       }
                                        border-bottom: 1px solid #d5d7de;
                                        &:hover{
                                            > a{
                                                background-color: #f2f3f8;
                                                font-weight: 700;
                                            }
                                        }
                                        > a{
                                            font-size: 18px;
                                            line-height: 18px;
                                            color: #2e3c3f;
                                            @include anim;
                                            padding: 18px 28px;
                                            background-color: #e8eaef;
                                            display: block;
                                            font-family: $font1;
                                            @media only screen and (max-width: 1500px) {
                                                font-size: 14px;
                                             }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 991px) {
        div.flex{
            width: 100%;
            display: flex;
            flex-flow: column;
            > div{
                align-items: stretch;
                &.bottom_left{
                    order:3;
                }
                &.bottom_middle{
                    order: 1;
                }
                &.bottom_right{
                    order: 2;
                }
            }
        }
    }
    .fonts{
        @media only screen and (max-width: 991px) {
            display: none !important;
        }
        ul{
            li{
                font-weight: 700;
                font-size: 16px;
                margin-right: 10px;
                cursor: pointer;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
}

section.slider{
    margin-top: -45px;
    @media only screen and (max-width: 991px) {
        margin-top: 0;
    }
    div.slides{
        position: relative;
        height: 310px;
        width: 100%;
        @media only screen and (max-width: 991px) {
            height: 400px;
        }
        > ul{
            @include fill;
            > li{
                @include fill;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                div.slider_cover{
                    @include fill;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: left top;
                    background-image: url('./../img/slider_cover.png');
                }
                .slider_content{
                    margin-top: 60px;
                    color: $white;
                    position: relative;
                    z-index: 2;
                    @media only screen and (max-width: 991px) {
                        text-align: center;
                    }
                    div.desc{
                        position: relative;
                        height: 170px;
                        @media only screen and (max-width: 991px) {
                            height: auto;
                            margin-bottom: 30px;
                        }
                        h1{
                            color: $white;
                            font-weight: 700;
                            font-family: $font2;
                            font-size: 34px;
                            @media only screen and (max-width: 1500px) {
                                font-size: 27px
                             }
                             @media only screen and (max-width: 991px) {
                                margin-bottom: 30px;
                             }
                        }
                        div.default_font{
                            @media only screen and (max-width: 991px) {
                                display: none;
                            }
                            font-weight: 300;
                            p{
                                font-weight: inherit;
                            }
                        }
                        div.more{
                            position: absolute;
                            width: 100%;
                            top: 100%;
                            left: 0;
                            border-top: 1px solid rgba(255,255,255,0.1);
                            @media only screen and (max-width: 991px) {
                                position: relative;
                                top: auto;
                                border-top: none;
                            }
                            a.more1{
                                position: relative;
                                top: -1px;
                            }
                        }
                    }
                }
            }
        }
        .slider_nav{
            position: absolute;
            left: 0;
            bottom: 45px;
            z-index: 2;
            @media only screen and (max-width: 991px) {
                bottom: 30px;
                width: 100%;
                text-align: center;
            }
            .slider_dots{
                width: 100%;
                > ul{
                    > li{
                        display: inline-block;
                        vertical-align: top;
                        cursor: pointer;
                        width: 16px;
                        height: 0;
                        padding-bottom: 12px;
                        border-radius: 50%;
                        border: 2px solid rgba(255,255,255,0.5);
                        margin-right: 13px;
                        @include anim;
                        &.on{
                            border: 2px solid $white;
                            background-color: $white;
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
   >  div.container{
        @media only screen and (max-width: 1199px) {
            width: 100%;
         }
         @media only screen and (max-width: 991px) {
            div.row{
                > div.col-md-12{
                    padding: 0;
                }
            }
        }
    }
}

main.content{
    border-top: 1px solid #d7dae4;
    background-color: $content_color;
}

.section_header{
    margin: 40px 0;
    border-left: 3px solid $blue2;
    @media only screen and (max-width: 991px) {
        margin: 30px 0;
        text-align: center;
        border-left: none;
        border-bottom: 3px solid $blue2;
    }
    div.default_font{
        padding-top: 19px;
        padding-bottom: 19px;
        font-family: $font1;
        font-weight: 700;
        padding-left: 30px;
        @media only screen and (max-width: 991px) {
            padding-top: 0;
            padding-left: 0;
        }
        h2{
            font-weight: inherit;
            color: $header_color;
        }
    }
}

.desc_header{
    color: $header_color;
    margin-bottom: 20px;
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    span{
        color: $blue2;
    }
    h3{
        font-size: 20px;
        @media only screen and (max-width: 1500px) {
            font-size: 15px;
        }
    }
}

section.news{
    div.news_list{
        ul{
            li{
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
               a{
                   text-decoration: none;
                   article{
                       &:hover{
                           div.image{
                               div.cover{
                                   opacity: 0;
                               }
                           }
                       }
                        div{
                            &.image{
                                height: 255px;
                                width: 100%;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;
                                position: relative;
                                div.cover{
                                    @include anim;
                                    @include fill;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: cover;
                                    background-image: url('./../img/news_cover.png');
                                    div.date{
                                        position: absolute;
                                        left: 27px;
                                        bottom: 10px;
                                        font-size: 14px;
                                        font-weight: 700;
                                        color: $white;
                                        z-index: 2;
                                        p{
                                            font-weight: inherit;
                                        }
                                    }
                                }
                            }
                            &.desc{
                                background-color: $white;
                                padding:30px 27px;
                                @media only screen and (max-width: 991px) {
                                    padding: 15px;
                                }
                                header{
                                    margin-bottom: 15px;
                                    h3{
                                        font-size: 20px;
                                        line-height: 30px;
                                        color: $header_color;
                                        font-weight: 700;
                                        font-family: $font1;
                                    }
                                }
                                div.default_font{
                                    margin-bottom: 27px;
                                    font-weight: 300;
                                    color: $font_color;
                                    p{
                                        font-weight: inherit;
                                    }
                                }
                                div.more{
                                    border-top: 1px solid #ebecef;
                                    @media only screen and (max-width: 991px) {
                                        border-top: none;
                                    }
                                    .more1{
                                        position: relative;
                                        top: -1px;
                                        @media only screen and (max-width: 991px) {
                                            top: 0;
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                   }
               }
            }
        }
        &.hidden_news{
            display: none;
        }
    }
    div.see_more_news{
        margin: 22px 0 40px 0;
    }
}

aside.side_bar{
    padding-top: 42px;
    @media only screen and (max-width: 991px) {
        padding-top: 0;
    }
    nav.side_menu{
        ul{
            li{
                margin-bottom: 17px;
                @include anim;
                &:hover{
                    opacity: 0.8;
                }
                a{
                    display: table;
                    height: 88px;
                    width: 100%;
                    text-decoration: none;
                    border-bottom: 3px solid #eaebf0;
                    > div{
                        display: table-cell;
                        vertical-align: middle;
                        &.icon{
                            background-repeat: no-repeat;
                            background-position: center;
                            background-color: $white;
                            width: 31.5%;
                             @media only screen and (max-width: 1500px) {
                                background-size: auto 50%;
                             }
                        }
                        &.desc{
                            color: $white;
                            font-size: 17px;
                            line-height: 24px;
                            width: 69.5%;
                            font-weight: 500;
                            font-family: $font1;
                            @media only screen and (max-width: 1500px) {
                                font-size: 14px;
                                line-height: 21px;
                             }
                             @media only screen and (max-width: 991px) {
                                text-align: center;
                             }
                            p{
                                padding: 0 19px;
                                font-weight: inherit;
                                @media only screen and (max-width: 1500px) {
                                    padding: 0 17px;
                                 }
                            }
                        }
                    }
                }
                &.contact_data{
                    div.desc{
                        background-color: $blue2;
                    }
                }
                &.bip{
                    div.desc{
                        background-color: $red;
                    }
                }
                &.fb{
                    div.desc{
                        background-color: $blue3;
                    }
                }
                &.family{
                    div.desc{
                        background-color: $green1;
                    }
                }
                &.family_card{
                    div.desc{
                        background-color: $yellow;
                    }
                }
                &.e-map{
                    div.desc{
                        background-color: $green2;
                    }
                }
            }
        }
    }
    div.boxes{
        ul{
            li{
                margin-bottom: 17px;
                a{
                    display: block;
                    div.side_box{
                        &:hover{
                            div.arrow{
                                left:40px;
                            }
                        }
                        padding: 30px;
                        height: 140px;
                        width: 100%;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        position: relative;
                        overflow: hidden;
                        @media only screen and (max-width: 991px) {
                            height: 240px;
                        }
                        div{
                            &.desc{
                                font-family: $font1;
                                color: $white;
                                font-size: 17px;
                                line-height: 24px;
                                position: relative;
                                z-index: 2;
                            }
                            &.cover{
                                @include fill;
                                background: rgba(76,76,76,1);
                                background: -moz-linear-gradient(top, rgba(76,76,76,1) 0%, rgba(76,76,76,0.73) 50%, rgba(76,76,76,0.45) 100%);
                                background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(76,76,76,0.73)), color-stop(100%, rgba(76,76,76,0.45)));
                                background: -webkit-linear-gradient(top, rgba(76,76,76,1) 0%, rgba(76,76,76,0.73) 50%, rgba(76,76,76,0.45) 100%);
                                background: -o-linear-gradient(top, rgba(76,76,76,1) 0%, rgba(76,76,76,0.73) 50%, rgba(76,76,76,0.45) 100%);
                                background: -ms-linear-gradient(top, rgba(76,76,76,1) 0%, rgba(76,76,76,0.73) 50%, rgba(76,76,76,0.45) 100%);
                                background: linear-gradient(to bottom, rgba(76,76,76,1) 0%, rgba(76,76,76,0.73) 50%, rgba(76,76,76,0.45) 100%);
                                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#4c4c4c', GradientType=0 );
                            }
                            &.arrow{
                                position: absolute;
                                left: 30px;
                                bottom: 20px;
                                @include anim;
                            }
                        }
                        
                    }
                }
            }
        }
    }
    div.white_boxes{
        ul{
            li{
                background-color: $white;
                margin-bottom: 17px;
                a{
                    display: block;
                    padding: 31px 29px;
                    text-decoration: none;
                    color: $header_color;
                    img{
                        max-width: 100%;
                        height: auto;
                    }
                    header{
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 700;
                        padding-bottom: 29px;
                        margin-bottom: 17px;
                        border-bottom: 1px solid #e8e9ef;
                        p{
                            font-weight: inherit;
                        }
                    }
                }
            }
        }
    }
}

section.articles{
    margin-bottom: 55px;
    @media only screen and (max-width: 991px) {
        margin-bottom: 20px;
    }
    .row{
        margin-left: -5px;
        margin-right: -5px;
        > div.col-md-12, div.col-md-6{
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    article{
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        position: relative;
        opacity: 1;
        @include anim;
        &:hover{
            opacity: 0.9;
        }
        div.cover{
            @include fill;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
        div.desc{
            position: absolute;
            left: 30px;
            padding-right: 30px;
            bottom: 40px;
            @media only screen and (max-width: 991px) {
                bottom:15px;
                left: 15px;
                padding-right: 15px;
            }
            .type{
                font-family: $font1;
                display: inline-block;
                background-color: rgba(90,106,109,0.9);
                padding: 10px;
                color: $white;
                font-weight: 600;
                color: $white;
                text-transform: uppercase;
                font-size: 16px;
                margin-bottom: 15px;
                p{
                    font-weight: inherit;
                }
            }
            div.default_font{
                color: $white;
                font-family: $font1;
                margin-bottom: 20px;
            }
        }
        &.large{
            height: 300px;
            margin-bottom: 10px;
            div.cover{
                background-image: url('./../img/article_cover1.png');
            }
        }
        &.small{
            height: 360px;
            @media only screen and (max-width: 991px) {
                height: 300px;
                margin-bottom: 10px;
            }
            div.cover{
                background-image: url('./../img/article_cover2.png');
            }
        }
    }
}

footer.footer{
    background-color: $content_color;
    div.container{
        padding-left: 25px;
        padding-right: 25px;
        @media only screen and (max-width: 1199px) {
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
         }
        .row{
            margin-left: -25px;
            margin-right: -25px;
            @media only screen and (max-width: 1199px) {
                margin-left: -15px;
                margin-right: -15px;
             }
            div.col-md-3, .col-md-8, .col-md-12{
                padding-left: 25px;
                padding-right: 25px;
                @media only screen and (max-width: 1199px) {
                    padding-left: 15px;
                    padding-right: 15px;
                 }
            }
            @media only screen and (max-width: 991px) {
                .col-md-12{
                    padding: 0;
                }
            }
        }
    }
    div{
        &.footer_top{
            .footer_content{
                border-top: 3px solid #5c6a6d;
                padding-top: 57px;
                padding-bottom: 58px;
                background-color: $dark2;
                position: relative;
                z-index: 2;
                @media only screen and (max-width: 991px) {
                    padding-top: 15px;
                    padding-bottom: 0;
                }
                div.default_font{
                    line-height: 27px;
                    font-size: 16px;
                    @media only screen and (max-width: 1500px) {
                        font-size: 13px;
                        line-height: 24px;
                    }
                    @media only screen and (max-width: 991px) {
                        text-align: center;
                        padding-bottom: 15px;
                        ul{
                            list-style: none;
                            padding-left: 0;
                        }
                    }
                    h5{
                        color: $white;
                        padding-bottom: 5px;
                        margin-bottom: 20px;
                        font-weight: 600;
                        font-family: $font1;
                        font-weight: inherit;
                        border-bottom:1px solid #586365;
                        @media only screen and (max-width: 991px) {
                            margin-bottom: 10px;
                        }
                    }
                    p{
                        color: #b4b9ba;
                        a{
                            color: inherit;
                        }
                    }
                }
                nav.footer_menu{
                    ul{
                        li{
                            color: $blue2;
                            margin-bottom: 10px;
                            a{
                                text-decoration: none;
                                color: #b4b9ba;
                                @include anim;
                                &:hover{
                                    color: $blue2;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.footer_bottom{
            background-color: $dark3;
            padding: 22px 0;
            font-family: $font1;
            color: #b4b9ba;
            font-size: 17px;
            line-height: 17px;
            position: relative;
            @media only screen and (max-width: 1500px) {
                font-size: 14px;
                line-height: 14px;
             }
             @media only screen and (max-width: 1500px) {
                font-size: 11px;
                line-height: 11px;
             }
            div.bottom_cover{
                background-color: $dark3;
                width: 100%;
                position: absolute;
                bottom: 100%;
                left: 0;
            }
            p{
                color: $white;
                &.to_up{
                    color: inherit;
                    cursor: pointer;
                    font-weight: 600;
                }
            }
            a, {
                color: inherit;
                font-weight: 600;
            }
            ul{
                li{
                    display: inline-block;
                    vertical-align: middle;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        text-align: center;
                    }
                }
            }
            div.footer_left{
                ul{
                    li{
                        &:first-child{
                            padding-top: 28px;
                            padding-bottom: 28px;
                            border-right: 1px solid rgba(255,255,255,0.1);
                            @media only screen and (max-width: 991px) {
                                padding-top: 16px;
                                padding-bottom: 16px;
                                border-right: none;
                            }
                        }
                        padding-top: 16px;
                        padding-bottom: 16px;
                        padding-left: 44px;
                        padding-right: 44px;
                        border-right: 1px solid rgba(255,255,255,0.1);
                        @media only screen and (max-width: 1500px) {
                            padding-right: 22px;
                            padding-left: 22px;
                         }
                         @media only screen and (max-width: 991px) {
                            padding-right: 0px;
                            padding-left: 0px;
                            border-right: none;
                        }
                        &:first-child{
                            padding-left: 0;
                        }
                        &:last-child{
                            padding-right: 0;
                            border: none;
                        }
                    }
                }
            }
            div.devs{
                padding-top:20px;
                img{
                    margin-left: 21px;
                    @media only screen and (max-width: 991px) {
                        margin-left: 0;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

section.oze{
    color: $font_color;
    div.desc{
        margin-bottom: 30px;
    }
}

section.count_energy{
    background-color: $white;
    padding: 30px;
    margin-bottom: 30px;;
    text-align: center;
    > header{
        margin-bottom: 30px;
    }
    div.default_font{
        margin-bottom: 20px;
    }
    div.instalation_list{
        ul{
            li{
                margin-bottom: 30px;
                &:last-child{
                    margin-bottom: 0;
                }
                &.solar{
                    div.image{
                        border-color: $blue2;
                    }
                    div.desc{
                        header{
                            color: $blue2;
                        }
                    }
                    div.counter{
                        > div{
                            background: rgba(61,179,253,1);
                            background: -moz-linear-gradient(top, rgba(61,179,253,1) 0%, rgba(40,155,237,1) 52%, rgba(17,128,218,1) 100%);
                            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(61,179,253,1)), color-stop(52%, rgba(40,155,237,1)), color-stop(100%, rgba(17,128,218,1)));
                            background: -webkit-linear-gradient(top, rgba(61,179,253,1) 0%, rgba(40,155,237,1) 52%, rgba(17,128,218,1) 100%);
                            background: -o-linear-gradient(top, rgba(61,179,253,1) 0%, rgba(40,155,237,1) 52%, rgba(17,128,218,1) 100%);
                            background: -ms-linear-gradient(top, rgba(61,179,253,1) 0%, rgba(40,155,237,1) 52%, rgba(17,128,218,1) 100%);
                            background: linear-gradient(to bottom, rgba(61,179,253,1) 0%, rgba(40,155,237,1) 52%, rgba(17,128,218,1) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3db3fd', endColorstr='#1180da', GradientType=0 );
                        }
                    }
                }
                &.furnance{
                    div.image{
                        border-color: $red;
                    }
                    div.desc{
                        header{
                            color: $red;
                        }
                    }
                    div.counter{
                        > div{
                            background: rgba(231,172,62,1);
                            background: -moz-linear-gradient(top, rgba(231,172,62,1) 0%, rgba(232,111,55,1) 52%, rgba(233,58,48,1) 100%);
                            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(231,172,62,1)), color-stop(52%, rgba(232,111,55,1)), color-stop(100%, rgba(233,58,48,1)));
                            background: -webkit-linear-gradient(top, rgba(231,172,62,1) 0%, rgba(232,111,55,1) 52%, rgba(233,58,48,1) 100%);
                            background: -o-linear-gradient(top, rgba(231,172,62,1) 0%, rgba(232,111,55,1) 52%, rgba(233,58,48,1) 100%);
                            background: -ms-linear-gradient(top, rgba(231,172,62,1) 0%, rgba(232,111,55,1) 52%, rgba(233,58,48,1) 100%);
                            background: linear-gradient(to bottom, rgba(231,172,62,1) 0%, rgba(232,111,55,1) 52%, rgba(233,58,48,1) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e7ac3e', endColorstr='#e93a30', GradientType=0 );
                        }
                    }
                }
                &.pomps{
                    div.image{
                        border-color: $yellow;
                    }
                    div.desc{
                        header{
                            color: $yellow;
                        }
                    }
                    div.counter{
                        > div{
                            background: rgba(246,204,65,1);
                            background: -moz-linear-gradient(top, rgba(246,204,65,1) 0%, rgba(242,176,54,1) 52%, rgba(239,151,44,1) 100%);
                            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(246,204,65,1)), color-stop(52%, rgba(242,176,54,1)), color-stop(100%, rgba(239,151,44,1)));
                            background: -webkit-linear-gradient(top, rgba(246,204,65,1) 0%, rgba(242,176,54,1) 52%, rgba(239,151,44,1) 100%);
                            background: -o-linear-gradient(top, rgba(246,204,65,1) 0%, rgba(242,176,54,1) 52%, rgba(239,151,44,1) 100%);
                            background: -ms-linear-gradient(top, rgba(246,204,65,1) 0%, rgba(242,176,54,1) 52%, rgba(239,151,44,1) 100%);
                            background: linear-gradient(to bottom, rgba(246,204,65,1) 0%, rgba(242,176,54,1) 52%, rgba(239,151,44,1) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6cc41', endColorstr='#ef972c', GradientType=0 );
                        }
                    }
                }
                &.foto{
                    div.image{
                        border-color: $green1;
                    }
                    div.desc{
                        header{
                            color: $green1;
                        }
                    }
                    div.counter{
                        > div{
                            background: rgba(101,227,87,1);
                            background: -moz-linear-gradient(top, rgba(101,227,87,1) 0%, rgba(57,200,86,1) 52%, rgba(17,174,85,1) 100%);
                            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(101,227,87,1)), color-stop(52%, rgba(57,200,86,1)), color-stop(100%, rgba(17,174,85,1)));
                            background: -webkit-linear-gradient(top, rgba(101,227,87,1) 0%, rgba(57,200,86,1) 52%, rgba(17,174,85,1) 100%);
                            background: -o-linear-gradient(top, rgba(101,227,87,1) 0%, rgba(57,200,86,1) 52%, rgba(17,174,85,1) 100%);
                            background: -ms-linear-gradient(top, rgba(101,227,87,1) 0%, rgba(57,200,86,1) 52%, rgba(17,174,85,1) 100%);
                            background: linear-gradient(to bottom, rgba(101,227,87,1) 0%, rgba(57,200,86,1) 52%, rgba(17,174,85,1) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#65e357', endColorstr='#11ae55', GradientType=0 );
                        }
                    }
                }
                div{
                    display: inline-block;
                    vertical-align: top;
                    &.image{
                        width: 50%;
                        height: 0;
                        padding-bottom: 45.5%;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        border: 5px solid;
                        margin-left: auto;
                        margin-right: auto;
                        display:block;
                        @media only screen and (max-width: 991px) {
                            width: 25%;
                            padding-bottom: 25%;
                        }
                    }
                    &.desc{
                        padding-top: 16px;
                        //padding-left: 30px;
                        //width:72%;
                        width: 100%;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            padding-left: 0;
                            text-align: center;
                        }
                        header{
                            margin-bottom: 15px;
                            h4{
                                font-size: 24px;
                                @media only screen and (max-width: 1500px) {
                                    font-size: 20px;
                                }
                            }
                        }
                        div.default_font{
                            color: $font_color;
                            font-weight: 300;
                            p{
                                font-weight: inherit;
                            }
                        }
                    }
                }
                div.counter_box{
                    div.counter{
                        display: block;
                        
                        border-collapse: separate;
                        border-spacing: 1px;
                        > div{
                            //height: 67px;
                            display: table-cell;
                            vertical-align: middle;
                            font-size: 30px;
                            font-family: $font1;
                            color: $white;
                            font-weight: 700;
                            width: 40px;
                            text-align: center;
                            &:last-child{
                                margin-right: 0;
                            }
                            p{
                                font-weight: inherit;
                            }
                        }
                    }
                }
            }
        }
    }
}

section.galery{
    margin-bottom: 30px;
    div.galery_list{
        ul{
            li{
                display: inline-block;
                vertical-align: top;
                width: 23%;
                height: 0;
                padding-bottom: 18%;
                position: relative;
                margin-right: 2.66%;
                margin-bottom: 2.66%;
                overflow: hidden;
                @media only screen and (max-width: 991px) {
                    width: 49%;
                    padding-bottom: 49%;
                    margin-right: 2%;
                    margin-bottom: 2%;
                    &:nth-child(4n-2){
                        margin-right: 0;
                    }
                }
                &:hover{
                    .image{
                        transform: scale(1.1);
                    }
                }
                &:nth-child(4n){
                    margin-right: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
                a{
                    display: block;
                    div.image{
                        @include fill;
                        @include anim;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                    }
                }
            }
        }
    }
}

section.download{
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #cfd0d2;
    @media only screen and (max-width: 991px) {
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
    div.download_list{
        ul{
            li{
                margin-bottom: 18px;
                &:last-child{
                    margin-bottom: 0;
                }
                a{
                    display: inline-block;
                    padding:7px 0 7px 42px;
                    background-repeat: no-repeat;
                    background-position: left center;
                    background-image: url('./../img/download_icon.png');
                    color: $font_color;
                    font-size: 15px;
                    @media only screen and (max-width: 991px) {
                        padding: 42px 0 0 0;
                        background-position: center top;
                        width: 100%;
                        text-align: center;
                    }
                    span{
                        font-weight: 300;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .text-right{
        text-align: center;
    }
}

.input{
    width: 50%;
    @media only screen and (max-width: 991px) {
        width: 100%;
    }
    p{
        margin-bottom: 5px !important;
    }
}

input[type=text], [type=password], [type=email], select{
    width: 100%;
    height: 62px;
    border-top:1px solid $blue2;
    border-left:2px solid $blue2;
    border-right:1px solid $blue2;
    border-bottom:2px solid $blue2;
    padding: 0 15px;
    margin-bottom: 15px;
    border-radius: 0;
    background-color: $white;
    font-size: 14px;
}

input[type=submit] {
    width: 144px;
    height: 51px;
    @include btn_gradient;
    color: $white;
    font-size: 15px;
    border: none;
    font-weight: 700;
  }

  .submit_holder{
      width: 50%;
      @media only screen and (max-width: 991px) {
        width: 100%;
      }
  }

  .register_form{
      width: 50%;
      font-size: 15px;
      line-height: 26px;
        p{
            margin-bottom: 5px !important;
        }
      p{
          margin-bottom: 15px;
          //text-transform: uppercase;
      }
  }

  div.check_box{
      > div{
          display: inline-block;
          vertical-align: top;
          padding-left: 5px;
      }
  }

  textarea{
    width: 100%;
    height: 200px;
    border-top:1px solid $blue2;
    border-left:2px solid $blue2;
    border-right:1px solid $blue2;
    border-bottom:2px solid $blue2;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 0;
    background-color: $white;
    font-size: 14px;
    resize: none;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #5b627e;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #5b627e;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #5b627e;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #5b627e;
  }

section.count_energy1{
    background-color: $white;
    padding: 30px;
    margin-bottom: 30px;;
    > header{
        margin-bottom: 30px;
    }
    div.default_font{
        margin-bottom: 20px;
    }
    div.instalation_list{
        ul{
            li{
                margin-bottom: 30px;
                &:last-child{
                    margin-bottom: 0;
                }
                &.solar{
                    div.image{
                        border-color: $blue2;
                    }
                    div.desc{
                        header{
                            color: $blue2;
                        }
                    }
                    div.counter{
                        > div{
                            background: rgba(61,179,253,1);
                            background: -moz-linear-gradient(top, rgba(61,179,253,1) 0%, rgba(40,155,237,1) 52%, rgba(17,128,218,1) 100%);
                            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(61,179,253,1)), color-stop(52%, rgba(40,155,237,1)), color-stop(100%, rgba(17,128,218,1)));
                            background: -webkit-linear-gradient(top, rgba(61,179,253,1) 0%, rgba(40,155,237,1) 52%, rgba(17,128,218,1) 100%);
                            background: -o-linear-gradient(top, rgba(61,179,253,1) 0%, rgba(40,155,237,1) 52%, rgba(17,128,218,1) 100%);
                            background: -ms-linear-gradient(top, rgba(61,179,253,1) 0%, rgba(40,155,237,1) 52%, rgba(17,128,218,1) 100%);
                            background: linear-gradient(to bottom, rgba(61,179,253,1) 0%, rgba(40,155,237,1) 52%, rgba(17,128,218,1) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3db3fd', endColorstr='#1180da', GradientType=0 );
                        }
                    }
                }
                &.furnance{
                    div.image{
                        border-color: $red;
                    }
                    div.desc{
                        header{
                            color: $red;
                        }
                    }
                    div.counter{
                        > div{
                            background: rgba(231,172,62,1);
                            background: -moz-linear-gradient(top, rgba(231,172,62,1) 0%, rgba(232,111,55,1) 52%, rgba(233,58,48,1) 100%);
                            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(231,172,62,1)), color-stop(52%, rgba(232,111,55,1)), color-stop(100%, rgba(233,58,48,1)));
                            background: -webkit-linear-gradient(top, rgba(231,172,62,1) 0%, rgba(232,111,55,1) 52%, rgba(233,58,48,1) 100%);
                            background: -o-linear-gradient(top, rgba(231,172,62,1) 0%, rgba(232,111,55,1) 52%, rgba(233,58,48,1) 100%);
                            background: -ms-linear-gradient(top, rgba(231,172,62,1) 0%, rgba(232,111,55,1) 52%, rgba(233,58,48,1) 100%);
                            background: linear-gradient(to bottom, rgba(231,172,62,1) 0%, rgba(232,111,55,1) 52%, rgba(233,58,48,1) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e7ac3e', endColorstr='#e93a30', GradientType=0 );
                        }
                    }
                }
                &.pomps{
                    div.image{
                        border-color: $yellow;
                    }
                    div.desc{
                        header{
                            color: $yellow;
                        }
                    }
                    div.counter{
                        > div{
                            background: rgba(246,204,65,1);
                            background: -moz-linear-gradient(top, rgba(246,204,65,1) 0%, rgba(242,176,54,1) 52%, rgba(239,151,44,1) 100%);
                            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(246,204,65,1)), color-stop(52%, rgba(242,176,54,1)), color-stop(100%, rgba(239,151,44,1)));
                            background: -webkit-linear-gradient(top, rgba(246,204,65,1) 0%, rgba(242,176,54,1) 52%, rgba(239,151,44,1) 100%);
                            background: -o-linear-gradient(top, rgba(246,204,65,1) 0%, rgba(242,176,54,1) 52%, rgba(239,151,44,1) 100%);
                            background: -ms-linear-gradient(top, rgba(246,204,65,1) 0%, rgba(242,176,54,1) 52%, rgba(239,151,44,1) 100%);
                            background: linear-gradient(to bottom, rgba(246,204,65,1) 0%, rgba(242,176,54,1) 52%, rgba(239,151,44,1) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6cc41', endColorstr='#ef972c', GradientType=0 );
                        }
                    }
                }
                &.foto{
                    div.image{
                        border-color: $green1;
                    }
                    div.desc{
                        header{
                            color: $green1;
                        }
                    }
                    div.counter{
                        > div{
                            background: rgba(101,227,87,1);
                            background: -moz-linear-gradient(top, rgba(101,227,87,1) 0%, rgba(57,200,86,1) 52%, rgba(17,174,85,1) 100%);
                            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(101,227,87,1)), color-stop(52%, rgba(57,200,86,1)), color-stop(100%, rgba(17,174,85,1)));
                            background: -webkit-linear-gradient(top, rgba(101,227,87,1) 0%, rgba(57,200,86,1) 52%, rgba(17,174,85,1) 100%);
                            background: -o-linear-gradient(top, rgba(101,227,87,1) 0%, rgba(57,200,86,1) 52%, rgba(17,174,85,1) 100%);
                            background: -ms-linear-gradient(top, rgba(101,227,87,1) 0%, rgba(57,200,86,1) 52%, rgba(17,174,85,1) 100%);
                            background: linear-gradient(to bottom, rgba(101,227,87,1) 0%, rgba(57,200,86,1) 52%, rgba(17,174,85,1) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#65e357', endColorstr='#11ae55', GradientType=0 );
                        }
                    }
                }
                div{
                    display: inline-block;
                    vertical-align: top;
                    &.image{
                        width: 28%;
                        height: 0;
                        padding-bottom: 175px;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        border: 5px solid;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                    &.desc{
                        padding-top: 16px;
                        padding-left: 30px;
                        width:72%;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            padding-left: 0;
                            text-align: center;
                        }
                        header{
                            margin-bottom: 15px;
                            h4{
                                font-size: 24px;
                            }
                        }
                        div.default_font{
                            color: $font_color;
                            font-weight: 300;
                            p{
                                font-weight: inherit;
                            }
                        }
                    }
                }
                div.counter_box{
                    div.counter{
                        display: block;

                        border-collapse: separate;
                        border-spacing: 1px;
                        > div{
                            height: 67px;
                            display: table-cell;
                            vertical-align: middle;
                            font-size: 36px;
                            font-family: $font1;
                            color: $white;
                            font-weight: 700;
                            width: 40px;
                            text-align: center;
                            &:last-child{
                                margin-right: 0;
                            }
                            p{
                                font-weight: inherit;
                            }
                        }
                    }
                }
            }
        }
    }
}